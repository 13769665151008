<template>
  <div class="d-flex justify-center login-container">
    <v-card width="500">
      <v-card-title class="d-flex justify-center text-h4">BonBon</v-card-title>
       <v-card-text>
        <p class="d-flex justify-center text-h5">{{ name }}</p>
      <div v-if="client._id&&client.activated">
      <form @submit.prevent>
          <v-text-field
            v-model="username"
            label="E-mailadres"
            type="text"
            required
            color="primary"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="newPassword"
            label="Wachtwoord"
            type="password"
            required
            color="primary"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="newPasswordRepeat"
            label="Wachtwoord"
            type="password"
            required
            color="primary"
            outlined
          ></v-text-field>
        
        <v-card-actions class="d-flex justify-center">
          <v-btn
            x-large
            color="primary"
            type="submit"
            :disabled="newPassword != newPasswordRepeat"
            @click="createAccount"
            >Aanmelden</v-btn
          >
        </v-card-actions>
      </form
      >
      </div>
      </v-card-text>
      <div class="text-h6 text-center">{{ message }}</div>
    </v-card>
  </div>
</template>

<script>
import { config } from "../config/config.mjs"
export default {
  name: "newAccount",
  data() {
    return {
      client: {},
      name: "",
      username: "",
      newPassword: "",
      newPasswordRepeat: "",
      message: ""
    }
  },
  methods: {
    async findClient(id) {
      try {
        let res = await fetch(
          `${config.api.host}/findClientById/${JSON.stringify(id)}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: "include"
          }
        )
        let result = await res.json()
        return result
      } catch (e) {
        console.log(e)
      }
    },
    async createAccount() {
      try {
        if(this.newPassword!==this.newPasswordRepeat)throw "Wrong password"
        let res = await fetch(`${config.api.host}/auth/createAccount`, {
          method: "POST",
          body: JSON.stringify({
            _id: this.client._id,
            username: this.username,
            password: this.newPassword
          }),
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
        let result = await res.json()
        if (result.success) {
          this.message = "Account aangemaakt"
          this.login()
        } else {
          this.message = "Er is iets fout gegaan"
        }
      } catch (e) {
        console.error(e)
      }
    },
    async login() {
      try {
        let result = await this.$store.dispatch("loginWithCredentials", {
          username: this.username,
          password: this.newPassword,
          keepAlive: true
        })
        if (result.success) {
          this.message = "Ingelogd"
        } else {
          this.message = "Onjuiste gebruikersnaam en/of wachtwoord"
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  async mounted() {
    try {
      if(this.$store.state.loggedIn){
        this.$store.dispatch("logoutWithoutRedirect")
      }
      if (this.$route.params) {
        let clientId = this.$route.params.client_id
        this.client = await this.findClient(clientId)
        if (this.client && this.client.activated) {
          this.name = "Klant is al geregistreerd"
        } else {
          this.name = this.client.name
          this.username = this.client.username
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 600px;
  position: absolute; /*Can also be `fixed`*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
</style>